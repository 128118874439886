// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyBmCPpEQTSuYH4-rMuxWQ-IOeenWPVQcgA",
    authDomain: "dlt-cybertech.firebaseapp.com",
    databaseURL: "https://dlt-cybertech.firebaseio.com",
    projectId: "dlt-cybertech",
    storageBucket: "dlt-cybertech.appspot.com",
    messagingSenderId: "446698284861",
    appId: "1:446698284861:web:fffc5b64841ebff44e8754",
    measurementId: "G-KNFQN5EEY4"
  }
};
