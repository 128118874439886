import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { Routes, RouterModule } from '@angular/router';


import { AngularFireModule } from '@angular/fire';
import { AngularFireStorageModule } from '@angular/fire/storage';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';


import { Approutes } from './app-routing.module';
import { AppComponent } from './app.component';

import { SpinnerComponent } from './shared/spinner.component';
import { SnackbarComponent } from './shared/snackbar/snackbar.component';
import { LoadingComponent } from './shared/loading/loading.component';
import 
{ 
  DialogComponent,
  DialogContentDialog,
  DialogDataDialog,
  DialogDetailDialog,
  DialogListDialog,
  DialogSuccessDialog,
  DialogInputDataDialog
} from './shared/dialog/dialog.component';





import { UsersService } from './services/users/users.service';
import { LoadingService } from './services/loading/loading.service';

import { MatDialogModule } from '@angular/material';
import {
  MatButtonModule,
  MatIconModule,
  MatFormFieldModule,
  MatInputModule,
  MatRippleModule,
  MatTooltipModule,
  MatTableModule,
  MatPaginatorModule,
  MatRadioModule,
  MatSelectModule,
  MatListModule,
  MatSnackBarModule,
  MatProgressSpinnerModule,
} from '@angular/material';


import { environment } from '../environments/environment';


@NgModule({
  entryComponents:[
    DialogComponent,
    DialogContentDialog,
    DialogDataDialog,
    DialogDetailDialog,
    DialogListDialog,
    DialogSuccessDialog,
    DialogInputDataDialog
  ],
  declarations: [
    AppComponent,
    SpinnerComponent,
    SnackbarComponent,
    LoadingComponent,
    DialogComponent,
    DialogContentDialog,
    DialogDataDialog,
    DialogDetailDialog,
    DialogListDialog,
    DialogSuccessDialog,
    DialogInputDataDialog
  ],
  imports: [
    CommonModule,
    AngularFireModule.initializeApp(environment.firebase),
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AngularFireStorageModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatRippleModule,
    MatTooltipModule,
    MatTableModule,
    MatPaginatorModule,
    MatRadioModule,
    MatSelectModule,
    MatListModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    NgbModule.forRoot(),
    RouterModule.forRoot(Approutes),
  ],
  providers:[
    UsersService,
    LoadingService,
    SnackbarComponent,
    DialogComponent,
    DialogDataDialog,
    DialogListDialog,
    DialogDetailDialog,
    DialogContentDialog,
    DialogSuccessDialog,
    DialogInputDataDialog
  ],
  exports:[
    LoadingComponent
  ],
  bootstrap: [AppComponent,LoadingComponent]
})
export class AppModule {}
