import { Countries } from "./countries";

export class AppSettings {
  // public static API_ENDPOINT = 'http://localhost:3003/api/';

  // public static API_ENDPOINT = 'http://192.168.0.143:3000/api/';

  public static API_ENDPOINT = "https://dltcybersec.com/api/";

  public static API_TIMEOUT = 3000; // 1 second Timeout

  public static LOGIN_ERROR =
    "Either username/password invalid. Please try again.";

  public static LOGOUT_SUCCESS = "Successfully Logout.";

  public static SITE_NAME = "DLT";

  public static GENERIC_ERROR = "There is some error. Please try again.";

  public static DEFAULT_COURSE = "5c4645c20a3168054e04f915";

  public static BUCKETPATH = {
    SERVICEBANNERIMAGE: "SERVICEBANNERIMAGE",
    VENDORDOC: "VENDOR",
    BANNERIMAGE: "BANNERIMAGE",
  };

  public static ROLE = {
    SUPERADMIN: "5d1b681f09cc8212e0a6ae34",
    ADMIN: "5d1b67c209cc8212e0a6ae31",
    SUBADMIN: "5d1b67f609cc8212e0a6ae32",
    FRONTEND: "5d1b680809cc8212e0a6ae33",
    VENDOR: "5d1c7186f47e2224a236e56e",
  };

  public static COUNTRIES_LIST = Countries.ALL_COUNTRIES;

  public static MESSAGE = {
    USER: {
      ADD_TITLE: "Registerd Successfully",
      ADD:
        "Congratulations you have created an account. A confirmation email has been sent. To activate the ClapApp, simply click on the word Verify in the email you received. Be sure to check your Clutter and Junk Email folders.",
      EDIT: "User Info has been updated",
      DELETE: "User has been deleted",
      DELETE_CONFIRM: "Are you sure you want to delete this user?",
      SIGNUP_ERR: "Email address already exist. Please try again.",
      FORGET_PASSWORD:
        "Reset Password link has been sent at your email address.",
      RESET_PASSWORD:
        "Congratulations! Your password has been reset successfully.",
      CHANGE_PASSWORD:
        "Congratulations! Your password has been changed successfully.",
    },
    BANNER: {
      ADD_TITLE: "Success",
      ADD: "Banner added successfully.",
      EDIT: "Banner Info has been updated",
      DELETE: "Banner has been deleted",
      DELETE_CONFIRM: "Are you sure you want to delete this Banner?",
      ERR: "There is some error, Please try again.",
    },
    PLAN: {
      ADD_TITLE: "Success",
      ADD: "Plan added successfully.",
      EDIT: "Plan Info has been updated",
      DELETE: "Plan has been deleted",
      DELETE_CONFIRM: "Are you sure you want to delete this Plan?",
      ERR: "There is some error, Please try again.",
    },
    RATING: {
      ADD_TITLE: "Success",
      ADD: "Rating added successfully.",
      EDIT: "Rating Info has been updated",
      DELETE: "Rating has been deleted",
      DELETE_CONFIRM: "Are you sure you want to delete this Rating?",
      ERR: "There is some error, Please try again.",
    },
    SERVICE: {
      ADD_TITLE: "Success",
      ADD: "Service added successfully.",
      EDIT: "Service Info has been updated",
      DELETE: "Service has been deleted",
      DELETE_CONFIRM: "Are you sure you want to delete this Service?",
      ERR: "There is some error, Please try again.",
    },
    TAX: {
      ADD_TITLE: "Success",
      ADD: "Tax added successfully.",
      EDIT: "Tax Info has been updated",
      DELETE: "Tax has been deleted",
      DELETE_CONFIRM: "Are you sure you want to delete this Tax?",
      ERR: "There is some error, Please try again.",
    },
    TRANSACTION: {
      ADD_TITLE: "Success",
      ADD: "Transaction added successfully.",
      EDIT: "Transaction Info has been updated",
      DELETE: "Transaction has been deleted",
      DELETE_CONFIRM: "Are you sure you want to delete this Transaction?",
      ERR: "There is some error, Please try again.",
    },
    VENDOR_AUCTION: {
      ADD_TITLE: "Success",
      ADD: "Vendor Auction added successfully.",
      EDIT: "Vendor Auction Info has been updated",
      DELETE: "Vendor Auction has been deleted",
      DELETE_CONFIRM: "Are you sure you want to delete this Vendor Auction?",
      ERR: "There is some error, Please try again.",
    },
    VENDOR_DETAIL: {
      ADD_TITLE: "Success",
      ADD: "Vendor Detail added successfully.",
      EDIT: "Vendor Detail Info has been updated",
      DELETE: "Vendor Detail has been deleted",
      DELETE_CONFIRM: "Are you sure you want to delete this Vendor Detail?",
      ERR: "There is some error, Please try again.",
    },
    VENDOR_DOC: {
      ADD_TITLE: "Success",
      ADD: "Vendor Document added successfully.",
      EDIT: "Vendor Document Info has been updated",
      DELETE: "Vendor Document has been deleted",
      DELETE_CONFIRM: "Are you sure you want to delete this Vendor Document?",
      ERR: "There is some error, Please try again.",
    },
    AWARD: {
      ADD_TITLE: "Success",
      ADD: "Award added successfully.",
      EDIT: "Award Info has been updated",
      DELETE: "Award has been deleted",
      DELETE_CONFIRM: "Are you sure you want to delete this Award?",
      ERR: "There is some error, Please try again.",
    },
    BANK: {
      ADD_TITLE: "Success",
      ADD: "Bank Account added successfully.",
      EDIT: "Bank Account Info has been updated",
      DELETE: "Bank Account has been deleted",
      DELETE_CONFIRM: "Are you sure you want to delete this Bank Account?",
      ERR: "There is some error, Please try again.",
    },
    COUPON: {
      ADD_TITLE: "Success",
      ADD: "Coupon added successfully.",
      EDIT: "Coupon Info has been updated",
      DELETE: "Coupon has been deleted",
      DELETE_CONFIRM: "Are you sure you want to delete this Coupon?",
      ERR: "There is some error, Please try again.",
    },
    JOB: {
      ADD_TITLE: "Success",
      ADD: "Job added successfully.",
      EDIT: "Job Info has been updated",
      DELETE: "Job has been deleted",
      DELETE_CONFIRM: "Are you sure you want to delete this Job?",
      ERR: "There is some error, Please try again.",
    },
    MUNIT: {
      ADD_TITLE: "Success",
      ADD: "Measurable Unit added successfully.",
      EDIT: "Measurable Unit Info has been updated",
      DELETE: "Measurable Unit has been deleted",
      DELETE_CONFIRM: "Are you sure you want to delete this Measurable Unit?",
      ERR: "There is some error, Please try again.",
    },
    MVAL: {
      ADD_TITLE: "Success",
      ADD: "Measurable Value added successfully.",
      EDIT: "Measurable Value Info has been updated",
      DELETE: "Measurable Value has been deleted",
      DELETE_CONFIRM: "Are you sure you want to delete this Measurable Value?",
      ERR: "There is some error, Please try again.",
    },
    PAYMENT: {
      ADD_TITLE: "Success",
      ADD: "Payment added successfully.",
      EDIT: "Payment Info has been updated",
      DELETE: "Payment has been deleted",
      DELETE_CONFIRM: "Are you sure you want to delete this Payment?",
      ERR: "There is some error, Please try again.",
    },
    AREA: {
      ADD_TITLE: "Success",
      ADD: "Area added successfully.",
      EDIT: "Area Info has been updated",
      DELETE: "Area has been deleted",
      DELETE_CONFIRM: "Are you sure you want to delete this Area?",
      ERR: "There is some error, Please try again.",
    },
    LOGIN: {
      VERFY_ACCESS_CODE_ERR: "Access Code is invalid. Please try again.",
    },
  };

  public static FIELDS = {
    THEME: {
      LISTING_IMAGE: "listing_image",
      DETAIL_IMAGE: "detail_image",
    },
    MEMORIAL: {
      LISTING_IMAGE: "profile_image",
      DETAIL_IMAGE: "cover_image",
    },
  };

  public static CSV_NAME = {
    FRONTEND_USER_LIST: "ClapApp Frontend Customers",
    SUBADMIN_LIST: "ClapApp Subadmin users",
    TAX_LIST: "ClapApp Tax list",
    COUPONS: "ClapApp Coupons List",
    PAYMENTS: "ClapApp Payments List",
    MEASUREABLEUNITS: "Measureable Units List",
    MEASUREABLEVALUES: "Measureable Values List",
  };

  public static URLS = {
    ADMIN: {
      DASHBOARD: "/dashboard",
      USERS: "/users",
      SUBADMIN: "/sub-admin",
      THEME: "/themes",
      SONG: "/songs",
      MEMORIAL: "/memorials",
      VENDOR: "/vendors",
      SERVICES: "/services",
      AREAMANAGERS: "/areamanagers",
      SUBSERVICE: "/sub-services",
      PLANS: "/plans",
      COUPONS: "/coupons",
      TAX: "/taxs",
      JOB: "/jobs",
      RATING: "/ratings",
      PAYMENT: "/payments",
      MEASUREABLEUNITS: "/MeasureUnits",
      MEASUREABLEVALUES: "/MeasureValue",
      BANNER: "/Banner",
      OFFER: "/Offer",
    },
  };

  public static SITE_TITLE_PREFIX = AppSettings.SITE_NAME + " - ";

  public static ADMIN_METADATA = {
    USER_LIST: {
      title: AppSettings.SITE_TITLE_PREFIX + "Frontend Users",
      urls: [
        { title: "Dashoard", url: AppSettings.URLS.ADMIN.DASHBOARD },
        { title: "Users" },
      ],
    },
    ADD_USER: {
      title: AppSettings.SITE_TITLE_PREFIX + "Invite User",
      urls: [
        { title: "Dashoard", url: AppSettings.URLS.ADMIN.DASHBOARD },
        { title: "Users", url: AppSettings.URLS.ADMIN.USERS },
        { title: "Invite User" },
      ],
    },
    INVITED_USER: {
      title: AppSettings.SITE_TITLE_PREFIX + "Invite User List",
      urls: [
        { title: "Dashoard", url: AppSettings.URLS.ADMIN.DASHBOARD },
        { title: "Users", url: AppSettings.URLS.ADMIN.USERS },
        { title: "Invited User List" },
      ],
    },
    EDIT_USER: {
      title: AppSettings.SITE_TITLE_PREFIX + "Edit Frontend User",
      urls: [
        { title: "Dashoard", url: AppSettings.URLS.ADMIN.DASHBOARD },
        { title: "Users", url: AppSettings.URLS.ADMIN.USERS },
        { title: "Edit User" },
      ],
    },
    VENDOR_LIST: {
      title: AppSettings.SITE_TITLE_PREFIX + "Vendor Listing",
      urls: [
        { title: "Dashoard", url: AppSettings.URLS.ADMIN.DASHBOARD },
        { title: "Vendor" },
      ],
    },
    ADD_VENDOR: {
      title: AppSettings.SITE_TITLE_PREFIX + "Add vendor",
      urls: [
        { title: "Dashoard", url: AppSettings.URLS.ADMIN.DASHBOARD },
        { title: "Vendor", url: AppSettings.URLS.ADMIN.VENDOR },
        { title: "Add Vendor" },
      ],
    },
    DOCS: {
      title: AppSettings.SITE_TITLE_PREFIX + "Vendor Docs",
      urls: [
        { title: "Dashoard", url: AppSettings.URLS.ADMIN.DASHBOARD },
        { title: "Vendor", url: AppSettings.URLS.ADMIN.VENDOR },
        { title: "Vendor Docs" },
      ],
    },
    Edit_VENDOR: {
      title: AppSettings.SITE_TITLE_PREFIX + "Edit vendor",
      urls: [
        { title: "Dashoard", url: AppSettings.URLS.ADMIN.DASHBOARD },
        { title: "Vendor", url: AppSettings.URLS.ADMIN.VENDOR },
        { title: "Edit Vendor" },
      ],
    },
    SERVICES_LIST: {
      title: AppSettings.SITE_TITLE_PREFIX + "Services Listing",
      urls: [
        { title: "Dashoard", url: AppSettings.URLS.ADMIN.DASHBOARD },
        { title: "Services" },
      ],
    },
    ADD_SERVICES: {
      title: AppSettings.SITE_TITLE_PREFIX + "Add Services",
      urls: [
        { title: "Dashoard", url: AppSettings.URLS.ADMIN.DASHBOARD },
        { title: "Services", url: AppSettings.URLS.ADMIN.SERVICES },
        { title: "Add Services" },
      ],
    },
    Edit_SERVICES: {
      title: AppSettings.SITE_TITLE_PREFIX + "Edit Services",
      urls: [
        { title: "Dashoard", url: AppSettings.URLS.ADMIN.DASHBOARD },
        { title: "Services", url: AppSettings.URLS.ADMIN.SERVICES },
        { title: "Edit Services" },
      ],
    },
    PLANS_LIST: {
      title: AppSettings.SITE_TITLE_PREFIX + "Plans Listing",
      urls: [
        { title: "Dashoard", url: AppSettings.URLS.ADMIN.DASHBOARD },
        { title: "Plans" },
      ],
    },
    ADD_PLANS: {
      title: AppSettings.SITE_TITLE_PREFIX + "Add Plans",
      urls: [
        { title: "Dashoard", url: AppSettings.URLS.ADMIN.DASHBOARD },
        { title: "Plans", url: AppSettings.URLS.ADMIN.PLANS },
        { title: "Add Plans" },
      ],
    },
    Edit_PLANS: {
      title: AppSettings.SITE_TITLE_PREFIX + "Edit Plans",
      urls: [
        { title: "Dashoard", url: AppSettings.URLS.ADMIN.DASHBOARD },
        { title: "Plans", url: AppSettings.URLS.ADMIN.PLANS },
        { title: "Edit Plans" },
      ],
    },
    AREAMANAGERS_LIST: {
      title: AppSettings.SITE_TITLE_PREFIX + "Area Manager Listing",
      urls: [
        { title: "Dashoard", url: AppSettings.URLS.ADMIN.DASHBOARD },
        { title: "Area Managers" },
      ],
    },
    ADD_AREAMANAGERS: {
      title: AppSettings.SITE_TITLE_PREFIX + "Add Area Manager",
      urls: [
        { title: "Dashoard", url: AppSettings.URLS.ADMIN.DASHBOARD },
        { title: "Area Managers", url: AppSettings.URLS.ADMIN.AREAMANAGERS },
        { title: "Add Area Managers" },
      ],
    },
    Edit_AREAMANAGERS: {
      title: AppSettings.SITE_TITLE_PREFIX + "Edit Area Manager",
      urls: [
        { title: "Dashoard", url: AppSettings.URLS.ADMIN.DASHBOARD },
        { title: "Area Managers", url: AppSettings.URLS.ADMIN.AREAMANAGERS },
        { title: "Edit Area Managers" },
      ],
    },
    COUPONS_LIST: {
      title: AppSettings.SITE_TITLE_PREFIX + "Coupons Listing",
      urls: [
        { title: "Dashoard", url: AppSettings.URLS.ADMIN.DASHBOARD },
        { title: "Coupons" },
      ],
    },
    ADD_COUPONS: {
      title: AppSettings.SITE_TITLE_PREFIX + "Add Coupons",
      urls: [
        { title: "Dashoard", url: AppSettings.URLS.ADMIN.DASHBOARD },
        { title: "Coupons", url: AppSettings.URLS.ADMIN.COUPONS },
        { title: "Add Coupons" },
      ],
    },
    Edit_COUPONS: {
      title: AppSettings.SITE_TITLE_PREFIX + "Edit Coupons",
      urls: [
        { title: "Dashoard", url: AppSettings.URLS.ADMIN.DASHBOARD },
        { title: "Coupons", url: AppSettings.URLS.ADMIN.COUPONS },
        { title: "Edit Coupons" },
      ],
    },
    TAXS_LIST: {
      title: AppSettings.SITE_TITLE_PREFIX + "Taxes Listing",
      urls: [
        { title: "Dashoard", url: AppSettings.URLS.ADMIN.DASHBOARD },
        { title: "Taxes" },
      ],
    },
    ADD_TAXS: {
      title: AppSettings.SITE_TITLE_PREFIX + "Add Tax",
      urls: [
        { title: "Dashoard", url: AppSettings.URLS.ADMIN.DASHBOARD },
        { title: "Taxes", url: AppSettings.URLS.ADMIN.TAX },
        { title: "Add Tax" },
      ],
    },
    Edit_TAXS: {
      title: AppSettings.SITE_TITLE_PREFIX + "Edit Tax",
      urls: [
        { title: "Dashoard", url: AppSettings.URLS.ADMIN.DASHBOARD },
        { title: "Taxes", url: AppSettings.URLS.ADMIN.TAX },
        { title: "Edit Tax" },
      ],
    },
    MEASUREABLE_UNITS_LIST: {
      title: AppSettings.SITE_TITLE_PREFIX + "Measureable Units Listing",
      urls: [
        { title: "Dashoard", url: AppSettings.URLS.ADMIN.DASHBOARD },
        { title: "Measureable Units" },
      ],
    },
    ADD_MEASUREABLE_UNITS: {
      title: AppSettings.SITE_TITLE_PREFIX + "Add Measureable Units",
      urls: [
        { title: "Dashoard", url: AppSettings.URLS.ADMIN.DASHBOARD },
        {
          title: "Measureable Units",
          url: AppSettings.URLS.ADMIN.MEASUREABLEUNITS,
        },
        { title: "Add Measureable Units" },
      ],
    },
    Edit_MEASUREABLE_UNITS: {
      title: AppSettings.SITE_TITLE_PREFIX + "Edit Measureable Units",
      urls: [
        { title: "Dashoard", url: AppSettings.URLS.ADMIN.DASHBOARD },
        { title: "Taxes", url: AppSettings.URLS.ADMIN.MEASUREABLEUNITS },
        { title: "Edit Measureable Units" },
      ],
    },

    MEASUREABLE_VALUES_LIST: {
      title: AppSettings.SITE_TITLE_PREFIX + "Measureable Values Listing",
      urls: [
        { title: "Dashoard", url: AppSettings.URLS.ADMIN.DASHBOARD },
        { title: "Measureable Values" },
      ],
    },
    ADD_MEASUREABLE_VALUES: {
      title: AppSettings.SITE_TITLE_PREFIX + "Add Measureable Values",
      urls: [
        { title: "Dashoard", url: AppSettings.URLS.ADMIN.DASHBOARD },
        {
          title: "Measureable Values",
          url: AppSettings.URLS.ADMIN.MEASUREABLEVALUES,
        },
        { title: "Add Measureable Values" },
      ],
    },
    Edit_MEASUREABLE_VALUES: {
      title: AppSettings.SITE_TITLE_PREFIX + "Edit Measureable Values",
      urls: [
        { title: "Dashoard", url: AppSettings.URLS.ADMIN.DASHBOARD },
        { title: "Taxes", url: AppSettings.URLS.ADMIN.MEASUREABLEVALUES },
        { title: "Edit Measureable Values" },
      ],
    },

    BANNER_LIST: {
      title: AppSettings.SITE_TITLE_PREFIX + "Banner Listing",
      urls: [
        { title: "Dashoard", url: AppSettings.URLS.ADMIN.DASHBOARD },
        { title: "Banner" },
      ],
    },
    ADD_BANNER: {
      title: AppSettings.SITE_TITLE_PREFIX + "Add Banner",
      urls: [
        { title: "Dashoard", url: AppSettings.URLS.ADMIN.DASHBOARD },
        { title: "Banner", url: AppSettings.URLS.ADMIN.BANNER },
        { title: "Add Banner" },
      ],
    },
    Edit_BANNER: {
      title: AppSettings.SITE_TITLE_PREFIX + "Edit Banner",
      urls: [
        { title: "Dashoard", url: AppSettings.URLS.ADMIN.DASHBOARD },
        { title: "Banner", url: AppSettings.URLS.ADMIN.BANNER },
        { title: "Edit Banner" },
      ],
    },

    OFFER_LIST: {
      title: AppSettings.SITE_TITLE_PREFIX + "Offers Listing",
      urls: [
        { title: "Dashoard", url: AppSettings.URLS.ADMIN.DASHBOARD },
        { title: "Offers" },
      ],
    },
    ADD_OFFER: {
      title: AppSettings.SITE_TITLE_PREFIX + "Add Offers",
      urls: [
        { title: "Dashoard", url: AppSettings.URLS.ADMIN.DASHBOARD },
        { title: "Offers", url: AppSettings.URLS.ADMIN.OFFER },
        { title: "Add Offers" },
      ],
    },
    Edit_OFFER: {
      title: AppSettings.SITE_TITLE_PREFIX + "Edit Offers",
      urls: [
        { title: "Dashoard", url: AppSettings.URLS.ADMIN.DASHBOARD },
        { title: "Offers", url: AppSettings.URLS.ADMIN.OFFER },
        { title: "Edit Offers" },
      ],
    },

    RATING_LIST: {
      title: AppSettings.SITE_TITLE_PREFIX + "Rating Listing",
      urls: [
        { title: "Dashoard", url: AppSettings.URLS.ADMIN.DASHBOARD },
        { title: "Rating" },
      ],
    },
    DETAIL_RATING: {
      title: AppSettings.SITE_TITLE_PREFIX + "Rating Detail",
      urls: [
        { title: "Dashoard", url: AppSettings.URLS.ADMIN.DASHBOARD },
        { title: "Rating", url: AppSettings.URLS.ADMIN.RATING },
        { title: "Add Rating" },
      ],
    },
    PAYMENT_LIST: {
      title: AppSettings.SITE_TITLE_PREFIX + "Payment Listing",
      urls: [
        { title: "Dashoard", url: AppSettings.URLS.ADMIN.DASHBOARD },
        { title: "Payment" },
      ],
    },
    PAYMENT_PARTNER_LIST: {
      title: AppSettings.SITE_TITLE_PREFIX + "Vendor Payment Listing",
      urls: [
        { title: "Dashoard", url: AppSettings.URLS.ADMIN.DASHBOARD },
        { title: "Payment", url: AppSettings.URLS.ADMIN.PAYMENT },
        { title: "Vendor Payment" },
      ],
    },
    PARTNER_JOBS_LIST: {
      title: AppSettings.SITE_TITLE_PREFIX + "Vendor Jobs Listing",
      urls: [
        { title: "Dashoard", url: AppSettings.URLS.ADMIN.DASHBOARD },
        { title: "Payment", url: AppSettings.URLS.ADMIN.PAYMENT },
        { title: "Vendor Jobs" },
      ],
    },
    TRANSACTION: {
      title: AppSettings.SITE_TITLE_PREFIX + "Transaction Information",
      urls: [
        { title: "Dashoard", url: AppSettings.URLS.ADMIN.DASHBOARD },
        { title: "Payment", url: AppSettings.URLS.ADMIN.PAYMENT },
        { title: "Transaction Information" },
      ],
    },
    DETAIL_PAYMENT: {
      title: AppSettings.SITE_TITLE_PREFIX + "Payment Detail",
      urls: [
        { title: "Dashoard", url: AppSettings.URLS.ADMIN.DASHBOARD },
        { title: "Payment", url: AppSettings.URLS.ADMIN.PAYMENT },
        { title: "Payment Details" },
      ],
    },
    JOBS_LIST: {
      title: AppSettings.SITE_TITLE_PREFIX + "Jobs Listing",
      urls: [
        { title: "Dashoard", url: AppSettings.URLS.ADMIN.DASHBOARD },
        { title: "Jobs" },
      ],
    },
    Edit_JOBS: {
      title: AppSettings.SITE_TITLE_PREFIX + "Edit Jobs",
      urls: [
        { title: "Dashoard", url: AppSettings.URLS.ADMIN.DASHBOARD },
        { title: "Jobs", url: AppSettings.URLS.ADMIN.JOB },
        { title: "Edit Jobs" },
      ],
    },
    SUBSERVICE_LIST: {
      title: AppSettings.SITE_TITLE_PREFIX + "Sub Service Listing",
      urls: [
        { title: "Dashoard", url: AppSettings.URLS.ADMIN.DASHBOARD },
        { title: "Sub Service" },
      ],
    },
    ADD_SUBSERVICE: {
      title: AppSettings.SITE_TITLE_PREFIX + "Add Sub Service",
      urls: [
        { title: "Dashoard", url: AppSettings.URLS.ADMIN.DASHBOARD },
        { title: "Sub Service", url: AppSettings.URLS.ADMIN.SUBSERVICE },
        { title: "Add Sub Service" },
      ],
    },
    Edit_SUBSERVICE: {
      title: AppSettings.SITE_TITLE_PREFIX + "Edit Sub Service",
      urls: [
        { title: "Dashoard", url: AppSettings.URLS.ADMIN.DASHBOARD },
        { title: "Sub Service", url: AppSettings.URLS.ADMIN.SUBSERVICE },
        { title: "Edit Sub Service" },
      ],
    },
    SUBADMIN_LIST: {
      title: AppSettings.SITE_TITLE_PREFIX + "Sub Admin Listing",
      urls: [
        { title: "Dashoard", url: AppSettings.URLS.ADMIN.DASHBOARD },
        { title: "Sub Admin" },
      ],
    },
    ADD_SUBADMIN: {
      title: AppSettings.SITE_TITLE_PREFIX + "Add Sub Admin",
      urls: [
        { title: "Dashoard", url: AppSettings.URLS.ADMIN.DASHBOARD },
        { title: "Sub Admin", url: AppSettings.URLS.ADMIN.SUBADMIN },
        { title: "Add Sub Admin" },
      ],
    },
    EDIT_SUBADMIN: {
      title: AppSettings.SITE_TITLE_PREFIX + "Edit Sub Admin",
      urls: [
        { title: "Dashoard", url: AppSettings.URLS.ADMIN.DASHBOARD },
        { title: "Sub Admin", url: AppSettings.URLS.ADMIN.SUBADMIN },
        { title: "Edit Sub Admin" },
      ],
    },

    THEME_LIST: {
      title: AppSettings.SITE_TITLE_PREFIX + "Themes",
      urls: [
        { title: "Dashoard", url: AppSettings.URLS.ADMIN.DASHBOARD },
        { title: "Themes" },
      ],
    },
    ADD_THEME: {
      title: AppSettings.SITE_TITLE_PREFIX + "Add Theme",
      urls: [
        { title: "Dashoard", url: AppSettings.URLS.ADMIN.DASHBOARD },
        { title: "Themes", url: AppSettings.URLS.ADMIN.THEME },
        { title: "Add Theme" },
      ],
    },
    EDIT_THEME: {
      title: AppSettings.SITE_TITLE_PREFIX + "Edit Theme",
      urls: [
        { title: "Dashoard", url: AppSettings.URLS.ADMIN.DASHBOARD },
        { title: "Themes", url: AppSettings.URLS.ADMIN.THEME },
        { title: "Edit Theme" },
      ],
    },

    MEMORIAL_LIST: {
      title: AppSettings.SITE_TITLE_PREFIX + "Memorials",
      urls: [
        { title: "Dashoard", url: AppSettings.URLS.ADMIN.DASHBOARD },
        { title: "Memorials" },
      ],
    },
    ADD_MEMORIAL: {
      title: AppSettings.SITE_TITLE_PREFIX + "Add Memorial",
      urls: [
        { title: "Dashoard", url: AppSettings.URLS.ADMIN.DASHBOARD },
        { title: "Memorials", url: AppSettings.URLS.ADMIN.MEMORIAL },
        { title: "Add Memorial" },
      ],
    },
    EDIT_MEMORIAL: {
      title: AppSettings.SITE_TITLE_PREFIX + "Edit Memorial",
      urls: [
        { title: "Dashoard", url: AppSettings.URLS.ADMIN.DASHBOARD },
        { title: "Memorials", url: AppSettings.URLS.ADMIN.MEMORIAL },
        { title: "Edit Memorial" },
      ],
    },

    SONG_LIST: {
      title: AppSettings.SITE_TITLE_PREFIX + "Songs",
      urls: [
        { title: "Dashoard", url: AppSettings.URLS.ADMIN.DASHBOARD },
        { title: "Songs" },
      ],
    },
    ADD_SONG: {
      title: AppSettings.SITE_TITLE_PREFIX + "Add Song",
      urls: [
        { title: "Dashoard", url: AppSettings.URLS.ADMIN.DASHBOARD },
        { title: "Songs", url: AppSettings.URLS.ADMIN.SONG },
        { title: "Add Song" },
      ],
    },
    EDIT_SONG: {
      title: AppSettings.SITE_TITLE_PREFIX + "Edit Song",
      urls: [
        { title: "Dashoard", url: AppSettings.URLS.ADMIN.DASHBOARD },
        { title: "Songs", url: AppSettings.URLS.ADMIN.SONG },
        { title: "Edit Song" },
      ],
    },
  };
}
