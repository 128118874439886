import { Component, OnInit } from '@angular/core';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material';

@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss']
})
export class SnackbarComponent implements OnInit {

  constructor(public snackBar: MatSnackBar) {}

  private configSucces: MatSnackBarConfig = {
    panelClass: ['style-succes'], 
    duration : 5000   
  };

  private configError: MatSnackBarConfig = {
    panelClass: ['style-error'],
    duration : 5000
  };

  public snackbarSucces(message) {
    this.snackBar.open(message, 'close', this.configSucces);
  }

  public snackbarError(message) {
    this.snackBar.open(message, 'close', this.configError);
  }

  ngOnInit() {
  }

}
